import React from 'react';
import FileUploadConflict from '../../components/conflict/FileUploadConflict';
const ConflictUpload = () => {
  return (

        <FileUploadConflict />

  );
};

export default ConflictUpload;
